<template>
  <v-container fluid fill-height>
    <v-row>
      <v-col md="10" offset-md="1">
        <v-card class="mx-auto" shaped raised>
          <v-btn text class="primary--text mt-5 ml-3" @click="login()">
            <v-icon>mdi-chevron-double-left</v-icon>Return to Login
          </v-btn>

          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="4" class="py-0">
                  <span class="font-weight-bold title">Client Details</span>
                  <v-form ref="form">
                    <v-text-field
                      v-model="data.employee_first_name"
                      label="First Name"
                      outlined
                      dense
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="data.employee_last_name"
                      label="Last Name"
                      outlined
                      dense
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="data.employee_middle_name"
                      label="Middle Name"
                      outlined
                      dense
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="data.employee_position"
                      label="Position"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      v-model="data.employee_contact_no"
                      label="Contact No."
                      type="number"
                      outlined
                      dense
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                    <!-- <v-text-field
                      v-model="data.employee_fax_no"
                      label="Fax No"
                      type="number"
                      outlined
                      dense
                    ></v-text-field> -->
                    <v-text-field
                      v-model="data.employee_email"
                      label="Email"
                      outlined
                      dense
                      required
                      :rules="[rules.required, rules.email]"
                    ></v-text-field>
                  </v-form>
                </v-col>
                <v-col cols="4" class="py-0">
                  <span class="font-weight-bold title">Business Details</span>
                  <v-form ref="form1">
                    <v-text-field
                      v-model="data.business_name"
                      label="Name"
                      outlined
                      dense
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="data.business_type"
                      label="Business Type"
                      outlined
                      dense
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="data.business_address"
                      label="Address"
                      outlined
                      dense
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                    <!-- <v-text-field
                      v-model="data.business_fax_no"
                      label="Fax No"
                      type="number"
                      outlined
                      dense
                    ></v-text-field> -->
                    <v-text-field
                      v-model="data.business_contact_no"
                      label="Contact No."
                      type="number"
                      outlined
                      dense
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                      v-model="data.business_email"
                      label="Email"
                      outlined
                      dense
                      required
                      :rules="[rules.required, rules.email]"
                    ></v-text-field>
                  </v-form>
                </v-col>
                <v-col cols="4" class="py-0">
                  <v-form ref="form2">
                    <span class="font-weight-bold title">Account Details</span>
                    <v-text-field
                      v-model="data.username"
                      label="Username"
                      outlined
                      dense
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                    <v-btn
                      color="primary"
                      @click="openConfirmSubmitMethod()"
                      block
                      >Submit</v-btn
                    >
                    <!-- <v-btn color="primary" @click="openSuccessDialog()" block
                      >TEST</v-btn
                    > -->
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-------- Here is the Confirm Submit Dialog --------->

    <mini-dialog
      :dialog="confirmSubmitDialog"
      :max-width="'400'"
      :confirmSave="true"
      :cardTitle="cardTitle"
      :cardText="cardText"
      :loading="confirmLoading"
      :checkLogo="checkLogo"
      @closeDialog="confirmSubmitDialog = false"
      @goToLogin="login()"
      @confirmDialog="saveRegistration()"
      :dialogType="dialogType"
    ></mini-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/Services";

export default {
  data: () => {
    return {
      data: {},
      confirmLoading: false,
      confirmSubmitDialog: false,
      dialogType: "",
      cardTitle: "",
      cardText: "",
      checkLogo: false,
      rules: {
        required: (value) => !!value || "This field is required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    resetForms() {
      this.$refs.form.reset();
      this.$refs.form1.reset();
      this.$refs.form2.reset();
    },
    openSuccessDialog() {
      setTimeout(() => {
        this.checkLogo = true;
      }, 800);

      this.dialogType = "success";
      this.cardTitle = "Thank you";
      this.cardText =
        "Your registration is successful, please wait for the approval of your company.";
      this.confirmSubmitDialog = true;
      this.resetForms();
    },
    openConfirmSubmitMethod() {
      this.dialogType = "confirmation";
      this.cardTitle = "Confirm";
      this.cardText = "Submit your registration?";
      this.confirmSubmitDialog = true;
    },
    async saveRegistration() {
      this.confirmLoading = true;

      const body = {
        company: {
          name: this.data.business_name,
          business_type: this.data.business_type,
          address: this.data.business_address,
          contact_no: this.data.business_contact_no,
          fax_no: this.data.business_fax_no,
          email: this.data.business_email,
        },
        employee: {
          first_name: this.data.employee_first_name,
          last_name: this.data.employee_last_name,
          middle_name: this.data.employee_middle_name,
          position: this.data.employee_position,
          contact_no: this.data.employee_contact_no,
          fax_no: this.data.employee_fax_no,
          email: this.data.employee_email,
        },
        user: {
          username: this.data.username,
        },
      };

      try {
        const response = await Services.Registration(body);

        if (
          response.status === 200 ||
          response.status === 201 ||
          response.status === 204
        ) {
          this.closeAllDialog();
          // this.$infoBar({
          //   type: "success",
          //   text: "Registration Successful!",
          // });

          setTimeout(() => {
            this.openSuccessDialog();
          }, 600);
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    login() {
      this.$router.push({
        path: "/",
      });
    },
    closeAllDialog() {
      this.confirmLoading = false;
      this.confirmSubmitDialog = false;
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
